import * as React from "react";
import { type ReactNode, useState } from "react";
import { ValidatedForm, useField } from "remix-validated-form";

import { CaretDownIcon } from "@radix-ui/react-icons";
import type * as LabelPrimitive from "@radix-ui/react-label";
import { type SelectProps, SelectValue } from "@radix-ui/react-select";
import { Slot } from "@radix-ui/react-slot";

import { Select, SelectContent, SelectTrigger } from "~/components/ui/select";
import { cn } from "~/lib/ui";

import { EyeIconActive, EyeIconInactive } from "../ui/eye-icon";
import { InfoIcon } from "../ui/icons/info-icon";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { TickIcon } from "../ui/tick";
import { FormErrors } from "./FormErrors";
import { GlobalErrors } from "./GlobalErrors";

/**
 * Usage:
 * 
 * 
 <Form>
 <GlobalErrors data?={actionData}/>
 <FormErrors />
 <FormField
  name="username">
    {(field)=>
      <FormItem>
        <FormLabel>Username</FormLabel>
        <FormControl>
          <Input placeholder="shadcn"  />
        </FormControl>
        <FormDescription>This is your public display name.</FormDescription>
        <FormMessage />
      </FormItem>
    }
  </FormField>

  // or

  <FormField
  name="username">
      <FormItem>
        <FormLabel>Username</FormLabel>
        <FormControl>
          <Input />
        </FormControl>
        <FormDescription>This is your public display name.</FormDescription>
        <FormMessage />
      </FormItem>
  </FormField>

  <FormFieldInput name="username" label="Username" description="This is your public display name." />
  </Form>
 */

const Form = ValidatedForm;

const FormFieldPassword = ({
  name,
  label,
  description,
  placeholder,
  displayEyeIcon = true,
  type: _type, //ignore the type and default to password
  ...props
}: {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  displayEyeIcon?: boolean;
  type?: "password";
} & Omit<React.ComponentProps<typeof Input>, "type">) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormField name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
        )}
        <div className="relative">
          <FormControl>
            <Input
              placeholder={placeholder}
              type={showPassword ? "text" : "password"}
              {...props}
            />
          </FormControl>

          {displayEyeIcon && (
            <button
              type="button"
              className="absolute inset-y-0 right-0 mr-4 flex items-center"
              onClick={() => setShowPassword(prev => !prev)}
            >
              {showPassword ? <EyeIconInactive /> : <EyeIconActive />}
            </button>
          )}
        </div>
        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    </FormField>
  );
};

export type InputFieldProps = {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
} & React.ComponentProps<typeof Input>;

const FormFieldInput = ({
  name,
  label,
  description,
  placeholder,
  ...props
}: InputFieldProps) => {
  return (
    <FormField name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-sm font-semibold text-brand-primary-black">
            {`${label}`}
          </FormLabel>
        )}

        <FormControl>
          <Input placeholder={placeholder} {...props} />
        </FormControl>

        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    </FormField>
  );
};

const FormFieldCheckbox = ({
  name,
  label,
  description,
  placeholder,
  labelClassName,
  ...props
}: {
  name: string;
  label?: React.ReactNode;
  description?: string;
  placeholder?: string;
  labelClassName?: string;
} & Omit<React.ComponentProps<typeof Input>, "type">) => (
  <FormField name={name} className="w-auto">
    <FormItem className="flex items-center space-y-0 ">
      <div className="relative mt-2">
        <TickIcon className="pointer-events-none absolute left-[6px] top-[6px] z-10" />
        <FormControl>
          <Input
            placeholder={placeholder}
            type="checkbox"
            variant="checkbox"
            name={name}
            {...props}
            className="pointer-events-auto relative z-20 mr-2 inline-block appearance-none rounded border-outline p-0 shadow-none checked:z-0 checked:border-none"
          />
        </FormControl>
      </div>
      {label && (
        <FormLabel
          className={cn(
            "text-contrast-black inline align-super font-semibold",
            labelClassName,
          )}
        >
          {label}
        </FormLabel>
      )}
      {description && <FormDescription>{description}</FormDescription>}
    </FormItem>
    <FormMessage />
  </FormField>
);

export type FieldType = ReturnType<typeof useFormField>;
type FormFieldContextValue = {
  name: string;
  validationBehavior?: "onChange" | "onBlur" | "onSubmit";
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);
const FormItemRender = ({
  children,
}: {
  children: ReactNode | ((field: FieldType) => ReactNode);
}) => {
  const field = useFormField();
  const body = typeof children === "function" ? children(field) : children;
  return body;
};
const FormField = ({
  name,
  children,
  className,
  validationBehavior,
}: {
  name: string;
  children: ReactNode | ((field: FieldType) => ReactNode);
  className?: string;
  validationBehavior?: "onChange" | "onBlur";
}) => {
  return (
    <div className={cn("my-2 md:my-3", className)}>
      <FormFieldContext.Provider value={{ validationBehavior, name: name }}>
        <FormItemRender>{children}</FormItemRender>
      </FormFieldContext.Provider>
    </div>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const fieldState = useField(fieldContext.name, {
    ...(fieldContext.validationBehavior
      ? {
          validationBehavior: {
            initial: fieldContext.validationBehavior,
            whenTouched: fieldContext.validationBehavior,
          },
        }
      : {}),
  });

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    errors: Array.isArray(fieldState.error)
      ? (fieldState.error as string[])
      : [],
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn("space-y-1", className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn("pb-0 text-base font-normal", className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const {
    error,
    formItemId,
    formDescriptionId,
    formMessageId,
    getInputProps,
    name,
  } = useFormField();
  const formProps = getInputProps();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      name={name}
      {...formProps}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <div className="grid grid-cols-[auto_1fr] items-center gap-3">
      <InfoIcon />
      <p
        ref={ref}
        id={formDescriptionId}
        className={cn("text-xs text-neutral-1", className)}
        {...props}
      />
    </div>
  );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn("text-xs font-normal text-error", className)}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

const FormSelect = ({
  options,
  placeholder,
  ...props
}: {
  name: string;
  placeholder?: string;
  "aria-invalid"?: boolean;
  options: ReactNode[];
  onChange?: (e: { target: { name: string; value: string } }) => void;
} & SelectProps) => {
  return (
    <Select
      {...props}
      onValueChange={(value: string) => {
        props.onChange?.({ target: { name: props.name, value } }); // remix-validated-form handler
        props.onValueChange?.(value); // custom handler
      }}
    >
      <SelectTrigger
        aria-invalid={props["aria-invalid"]}
        className={cn(
          " my-2 flex h-[52px] flex-col items-start justify-center rounded-[4px]  border-primary  bg-white [&>svg]:hidden",
          `aria-[invalid="true"]:border-error   aria-[invalid="true"]:focus:ring-error`,
        )}
      >
        <span
          className={cn(
            "text-primary-100 flex w-full flex-row items-center justify-between text-sm font-semibold ",
          )}
        >
          <SelectValue placeholder={placeholder} />
          <CaretDownIcon height="24px" width="24px" />
        </span>
      </SelectTrigger>
      <SelectContent
        position="popper"
        className={cn("bg-white focus:outline-none")}
      >
        {options}
      </SelectContent>
    </Select>
  );
};

const FormFieldSelect = ({
  options,
  label,
  name,
  placeholder,
  description,
  ...props
}: {
  name: string;
  label: string;
  placeholder?: string;
  description?: string;
  options: ReactNode[];
} & SelectProps) => {
  return (
    <FormField name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-contrast-black text-sm font-semibold">
            {label}
          </FormLabel>
        )}
        <FormControl className={'aria-[invalid="true"]:bg-error'}>
          <FormSelect
            name={name}
            options={options}
            placeholder={placeholder}
            {...props}
          />
        </FormControl>
        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    </FormField>
  );
};

export type TextareaProps = {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
} & React.ComponentProps<typeof Textarea>;

const FormFieldTextarea = ({
  name,
  label,
  description,
  placeholder,
  ...props
}: TextareaProps) => {
  return (
    <FormField name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-contrast-black text-sm font-semibold">
            {`${label}`}
          </FormLabel>
        )}
        <FormControl>
          <Textarea placeholder={placeholder} {...props} />
        </FormControl>
        {description && <FormDescription>{description}</FormDescription>}
        <FormMessage />
      </FormItem>
    </FormField>
  );
};

export {
  Form,
  FormControl,
  FormDescription,
  FormErrors,
  FormField,
  FormFieldCheckbox,
  FormFieldInput,
  FormFieldPassword,
  FormFieldSelect,
  FormFieldTextarea,
  FormItem,
  FormLabel,
  FormMessage,
  GlobalErrors,
  useFormField,
};
