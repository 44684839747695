import * as React from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { cn } from "~/lib/ui";

import { CheckboxCheckedIcon } from "./icons/checkbox-checked-icon";

const inputVariants = tv({
  base: "flex h-[52px] w-full rounded-[4px] border border-brand-primary-black bg-transparent bg-white px-3 py-2 text-base font-normal transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:border-2 focus-visible:border-black focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid='true']:border-error aria-[invalid='true']:focus-visible:border-error",
  variants: {
    variant: {
      default: "font-semibold",
      radio: "accent-black",
      checkbox: "checked:bg-black w-6 h-6",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "3",
  },
});
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  iconSize?: number;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant = "default", type, ...props }, ref) => {
    return (
      <input
        suppressHydrationWarning
        type={type}
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

const Checkbox = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, iconSize, ...props }, ref) => {
    return (
      <div className="relative h-6 w-6">
        <input
          {...props}
          ref={ref}
          type="checkbox"
          className={cn(
            "peer flex h-full w-full cursor-pointer appearance-none rounded-md border border-black bg-transparent bg-white px-3 py-2 text-base font-normal  transition-all file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground before:absolute before:block before:h-12 before:w-12 before:rounded-full focus-visible:border-2 focus-visible:border-black focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid='true']:border-red-100 aria-[invalid='true']:focus-visible:border-red-100",
            className,
          )}
        />
        <span className="pointer-events-none absolute top-0 opacity-0 transition-opacity peer-checked:opacity-100">
          {props.checked && <CheckboxCheckedIcon size={iconSize} />}
        </span>
      </div>
    );
  },
);
Checkbox.displayName = "CheckboxInput";

export { Checkbox, Input };
